/*--------------------------------------------------------------
5. Slider
----------------------------------------------------------------*/
.slick-initialized .slick-slide > div {
  display: flex;
  > * {
    width: 100%;
  }
}
.cs_gap_20 {
  .slick-slide {
    padding-left: 10px;
    padding-right: 10px;
  }
  .slick-list {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.cs_slider_navigation.cs_style_1 {
  display: flex;
  gap: 25px;
  @media (max-width: 1700px) {
    gap: 15px;
  }
  .cs_slider_prev,
  .cs_slider_next {
    width: 90px;
    height: 70px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
    @media (max-width: 1700px) {
      width: 80px;
      height: 60px;
    }
    img {
      transition: inherit;
    }
    &:hover {
      background-color: $accent;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  @media (max-width: 575px) {
    margin-top: 40px;
  }
}
.cs_slider_navigation.cs_style_2 {
  display: flex;
  gap: 5px;
  margin-top: 10px;
  position: relative;
  z-index: 5;
  .cs_slider_prev,
  .cs_slider_next {
    height: 40px;
    width: 40px;
    border: 2px solid $accent;
    border-radius: 50%;
    padding: 8px;
    transition: all 0.4s ease;
    cursor: pointer;
    img {
      transition: inherit;
    }
    &:hover {
      background-color: $accent;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
.slick-dots {
  display: flex !important;
  justify-content: center;
  gap: 10px 15px;
  margin-top: 60px;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
  li {
    height: 12px;
    width: 12px;
    background-color: #fff;
    border: 2px solid $accent;
    opacity: 1;
    border-radius: 1.6em;
    transition: all 0.4s ease;
    overflow: hidden;
    position: relative;
    button {
      position: absolute;
      top: -10px;
      left: -10px;
      opacity: 0;
      z-index: 1;
    }
    &.slick-active {
      background-color: $accent;
    }
  }
}
.cs_slider_heading_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
  }
  @media (max-width: 575px) {
    .cs_slider_navigation.cs_style_1 {
      margin-top: 0;
    }
  }
}
.cs_related_doctor {
  .cs_slider_navigation.cs_style_2 {
    margin-top: 50px;
    justify-content: flex-end;
    max-width: 50%;
    margin-left: auto;
    @media (max-width: 991px) {
      margin-top: 30px;
      max-width: 100%;
      justify-content: center;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: -76px;
    margin-top: 0;
    z-index: 6;
    gap: 10px 18px;
    z-index: 3;
    @media (max-width: 991px) {
      display: none !important;
    }
    li {
      background-color: $accent;
      &.slick-active {
        transform: scale(1.3);
      }
    }
  }
  @media (max-width: 1400px) {
    .slick-list {
      overflow: initial;
      .slick-slide {
        opacity: 0;
        transition: all 0.4s ease;
        &.slick-current {
          opacity: 1;
        }
      }
    }
  }
}
.cs_department_carousel {
  position: relative;
  .slick-list {
    overflow: initial;
  }
  .slick-slide {
    opacity: 0;
    transition: all 0.3s ease;
    &.slick-active {
      opacity: 1;
    }
  }
  .cs_slider_navigation.cs_style_1 {
    .cs_slider_prev,
    .cs_slider_next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fff;
      width: 70px;
      box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
      opacity: 0;
      @media (max-width: 991px) {
        opacity: 1;
        width: 50px;
        height: 50px;
        padding: 12px;
      }
      img {
        filter: initial;
      }
    }
    .cs_slider_prev {
      left: -35px;
      @media (max-width: 991px) {
        left: -15px;
      }
    }
    .cs_slider_next {
      right: -35px;
      @media (max-width: 991px) {
        right: -15px;
      }
    }
  }
  &:hover {
    .cs_slider_navigation.cs_style_1 {
      .cs_slider_next,
      .cs_slider_prev {
        opacity: 1;
      }
    }
  }
}
